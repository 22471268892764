import React from 'react';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import i18n from '@/translate/i18n';
import { ReactComponent as Logo } from '@/assets/logo.svg';
import { ReactComponent as SimpleLogo } from '@/assets/simple-logo-alternative.svg';
import { ReactComponent as MinimumAgeIcon } from '@/assets/icons/minimum-age.svg';
import {
  ExternalLink,
  NavLink,
  Text,
} from '@/components/atoms/Typography/styles';
import { FlexDiv } from '@/components/atoms/Containers/styles';

import {
  Container,
  ContentWrapper,
  NavLinkWrapper,
  Section,
  StackContainer,
} from './styles';

function Footer({ renderBusinessLinks }) {
  const i18nextKey = renderBusinessLinks ? 'merchantFooter' : 'footer';
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Container>
        <ContentWrapper>
          <StackContainer>
            <Logo className="logo" />
            <SimpleLogo className="simple-logo" />
          </StackContainer>
          <FlexDiv direction="column" gap="3rem">
            <Text color="light" size="xs" variant="secondary">
              <Trans
                i18n={i18n}
                i18nKey={`${i18nextKey}.trademarkContent`}
                components={[
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={{
                      pathname:
                        'https://www.lb.lt/en/enforcement-measures-1/view_license?id=425',
                    }}
                  />,
                ]}
              />
            </Text>
            <NavLinkWrapper>
              <NavLink to="/minimum-age" className="minimum-age">
                <MinimumAgeIcon />
              </NavLink>
              {renderBusinessLinks ? (
                <ul>
                  {i18n.t(`${i18nextKey}.developerIntegrationGuide`)}
                  <li id="integrationGuide">
                    <ExternalLink
                      href="http://docs.gateway.vpag.com"
                      target="_blank"
                      size="lg"
                      color="light"
                      variant="secondary"
                    >
                      {i18n.t('footer.vpagFlash')}
                    </ExternalLink>
                  </li>
                  <li>
                    <ExternalLink
                      href="http://docs.wallet.vpag.com"
                      target="_blank"
                      size="lg"
                      color="light"
                      variant="secondary"
                    >
                      {i18n.t('footer.vpagWallet')}
                    </ExternalLink>
                  </li>
                </ul>
              ) : (
                <NavLink
                  to="/business"
                  size="lg"
                  color="light"
                  variant="secondary"
                >
                  {i18n.t('header.merchants')}
                </NavLink>
              )}

              <ul>
                {i18n.t(`${i18nextKey}.privacyPolicy`)}
                <li>
                  <NavLink
                    to="/business/privacy-policy"
                    color="light"
                    variant="secondary"
                  >
                    {i18n.t('footer.vpagFlash')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/privacy-policy"
                    size="lg"
                    color="light"
                    variant="secondary"
                  >
                    {i18n.t('footer.vpagWallet')}
                  </NavLink>
                </li>
              </ul>

              <ul>
                {i18n.t(`${i18nextKey}.termsAndConditions`)}
                <li>
                  <NavLink
                    to="/business/terms-and-conditions"
                    color="light"
                    variant="secondary"
                  >
                    {i18n.t('footer.vpagFlash')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/terms-and-conditions"
                    size="lg"
                    color="light"
                    variant="secondary"
                  >
                    {i18n.t('footer.vpagWallet')}
                  </NavLink>
                </li>
              </ul>
              <NavLink
                to="/cookie-policy"
                size="lg"
                color="light"
                variant="secondary"
              >
                {i18n.t(`${i18nextKey}.cookiesPolicy`)}
              </NavLink>
              <NavLink
                to="/responsible-gaming"
                size="lg"
                color="light"
                variant="secondary"
              >
                {i18n.t(`${i18nextKey}.responsibleGaming`)}
              </NavLink>
              {!renderBusinessLinks && (
                <NavLink to="/faq" size="lg" color="light" variant="secondary">
                  {i18n.t(`${i18nextKey}.faq`)}
                </NavLink>
              )}
            </NavLinkWrapper>
          </FlexDiv>
        </ContentWrapper>
      </Container>
      <Section>
        <Text color="light" size="sm" variant="secondary">
          ®{currentYear} {i18n.t(`${i18nextKey}.allRightsReserved`)}
        </Text>
      </Section>
    </>
  );
}

Footer.propTypes = {
  renderBusinessLinks: PropTypes.bool,
};

export default Footer;
