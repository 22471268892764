import React from 'react';
import PropTypes from 'prop-types';
import { CaretDown } from 'phosphor-react';

import { Button } from './styles';

function CollapseButton({ isOpen, onClick }) {
  return (
    <Button type="button" isOpen={isOpen} onClick={onClick}>
      <CaretDown size={24} weight="bold" />
    </Button>
  );
}

CollapseButton.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default CollapseButton;
