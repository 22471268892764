import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import textContent from './languages';

if (localStorage.getItem('i18nextLng') === null) {
  localStorage.setItem('i18nextLng', 'en');
}

i18n.use(LanguageDetector).init({
  debug: false,
  defaultNS: ['translations'],
  fallbackLng: 'en',
  ns: ['translations'],
  resources: textContent,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
