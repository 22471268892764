import styled from 'styled-components';

export const TextWrapper = styled.div`
  padding: 0.8rem 0;

  a {
    color: ${({ theme }) => theme.colors.link.primary};
    font: ${({ theme }) => theme.font.family.primary};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  li {
    list-style-position: inside;
    margin-bottom: 0.5rem;
    padding-left: 2em;
    text-indent: -1.3em;
  }

  p {
    text-indent: 2em;
  }
`;

export const LinksWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: center;
  max-width: 550px;
  width: 100%;
`;

export const LinkButton = styled.a`
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.shadow};
  padding: 10px;
  text-align: center;
  text-decoration: none;

  &:hover {
    filter: brightness(0.95);
  }
`;
